import { Areas } from "../components/areas/areas"
import { ContainerWithPadding } from "../components/shared"
import Layout from "../components/Layout"
import { Principles } from "../components/principles/principles"
import React, { FC } from "react"
import { ShortDescription } from "../components/ShortDescription/ShortDescription"
import { TextBlock } from "../components/info-title/text-block"
import { TopBlock } from "../components/TopBlock/TopBlock"
import { TopBlockCounters } from "../components/TopBlock/TopBlockCounters"
import { graphql } from "gatsby"
import { PageWithQuery } from "../types/PageWithQuery"

const IndexPage: FC<
  PageWithQuery<{
    title: string
    aboutBlock: { title: string; content: string }
    bottomBlock: { title: string; content: string }
    principles: {
      principlesTitle: string
      principlesList: {
        icon: string
        title: string
        text: string
      }[]
    }
    areas: {
      title: string
      areasList: {
        title: string
        background: string
        icon: string
      }[]
    }
  }>
> = ({
  data: {
    pageData: {
      frontmatter: {
        aboutBlock: { title: aboutTitle, content: aboutContent },
        principles: { principlesList, principlesTitle },
        bottomBlock: { title: bottomBlockTitle, content: bottomBlockContent },
        areas: { title: areasTitle, areasList },
      },
    },
    translationData: {
      frontmatter: {
        topBlockStatistic,
        address,
        navigationItems,
        contactUsTitle,
      },
    },
    commonData: {
      frontmatter: { phoneNumber, email, addressCoordinates },
    },
  },
}) => {
  return (
    <Layout
      lang="ru"
      phoneNumber={phoneNumber}
      email={email}
      address={address}
      navigationItems={navigationItems}
      addressCoordinates={addressCoordinates}
      contactUsTitle={contactUsTitle}
    >
      <ContainerWithPadding padding={`32px 32px 0`}>
        <TopBlock>
          <div className="bottom">
            <TopBlockCounters counters={Object.values(topBlockStatistic)} />
          </div>
        </TopBlock>
      </ContainerWithPadding>
      <TextBlock title={aboutTitle} text={aboutContent} />
      <Principles principleData={principlesList} title={principlesTitle} />
      <Areas
        areasList={areasList}
        title={areasTitle}
        moreAreasTitle="Больше областей права"
      />
      <ShortDescription title={bottomBlockTitle} content={bottomBlockContent} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    pageData: markdownRemark(
      frontmatter: { lang: { eq: "ru" }, pageType: { eq: "home" } }
    ) {
      id
      timeToRead
      frontmatter {
        title
        aboutBlock {
          title
          content
        }
        bottomBlock {
          title
          content
        }
        principles {
          principlesTitle
          principlesList {
            title
            icon
            text
          }
        }
        areas {
          title
          areasList {
            title
            background
            icon
          }
        }
      }
    }
    translationData: markdownRemark(
      frontmatter: { lang: { eq: "ru" }, pageType: { eq: "translations" } }
    ) {
      frontmatter {
        navigationItems {
          about
          blog
          contacts
          team
        }
        contactUsTitle
        address
        topBlockStatistic {
          ares {
            number
            title
          }
          wonCases {
            number
            title
          }
          years {
            number
            title
          }
        }
      }
    }
    commonData: markdownRemark(frontmatter: { pageType: { eq: "common" } }) {
      frontmatter {
        phoneNumber
        email
        addressCoordinates {
          lat
          lng
        }
      }
    }
  }
`
